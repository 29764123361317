<template>
    <div class="spinner"></div>
</template>

<script>
export default {
    name: 'SpinnerSmall'
}
</script>

<style scoped>
.spinner {
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#203864 94%,#0000) top/3.8px 3.8px no-repeat,
          conic-gradient(#0000 30%,#203864);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
   mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
   animation: spinner-c7wet2 0.8s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}
</style>