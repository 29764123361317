import { createWebHistory, createRouter } from 'vue-router';
import Login from '../views/auth/LoginPage';

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Dispatch | Login',
			metaTags: [
				{
					name: 'description',
					content: 'The login page of Dispatch',
				},
			],
		},
	},
	{
		path: '/auth/setpassword',
		name: 'SetPassword',
		component: () => import('../views/auth/SetPassword.vue'),
		meta: {
			title: 'Dispatch | Set Password',
			metaTags: [
				{
					name: 'description',
					content: 'The set password page of Dispatch',
				},
			],
		},
	},
	{
		path: '/auth/selectplatform',
		name: 'SelectPlatform',
		component: () => import('../views/auth/SelectPlatform.vue'),
		meta: {
			title: 'Dispatch | Select Platform',
			metaTags: [
				{
					name: 'description',
					content: 'The select platform page of Dispatch',
				},
			],
		},
	},
	{
		path: '/teams/add',
		name: 'AddTeams',
		component: () => import('../views/teams/AddTeam.vue'),
		meta: { title: 'Dispatch | Add Team' },
	},
	{
		path: '/teams',
		name: 'ViewTeams',
		component: () => import('../views/teams/ViewTeams.vue'),
		meta: { title: 'Dispatch | Teams' },
	},
	{
		path: '/tickets/add',
		name: 'AddTickets',
		component: () => import('../views/tickets/AddTickets.vue'),
		meta: { title: 'Dispatch | Add Ticket' },
	},
	{
		path: '/tickets',
		name: 'ViewTickets',
		component: () => import('../views/tickets/ViewTickets.vue'),
		meta: { title: 'Dispatch | Tickets' },
	},
	{
		path: '/ticket/:id',
		name: 'ViewTicket',
		component: () => import('../views/tickets/ViewTicket.vue'),
		meta: { title: 'Dispatch | View Ticket' },
	},
	{
		path: '/ticket/:id/schedule',
		name: 'ScheduleTicket',
		component: () => import('../views/tickets/AddTicket.vue'),
		meta: { title: 'Dispatch | Schedule Ticket' },
	},
	{
		path: '/ticket/:id/logs',
		name: 'TicketLog',
		component: () => import('../views/tickets/TicketLog.vue'),
		meta: { title: 'Dispatch | Ticket Log' },
	},
	{
		path: '/projects/add',
		name: 'AddProject',
		component: () => import('../views/projects/AddProject.vue'),
		meta: { title: 'Dispatch | Add Project' },
	},
	{
		path: '/schedule',
		name: 'MainSchedule',
		component: () => import('../views/schedule/MainSchedule.vue'),
		meta: { title: 'Dispatch | Schedule' },
	},
	{
		path: '/projects',
		name: 'ManageProjects',
		component: () => import('../views/projects/ViewProjects.vue'),
		meta: { title: 'Dispatch | Projects' },
	},
	{
		path: '/projects/archived',
		name: 'ViewArchivedProjects',
		component: () => import('../views/projects/ViewArchivedProjects.vue'),
		meta: { title: 'Dispatch | Archived Projects' },
	},
	{
		path: '/material/stockdata',
		name: 'StockData',
		component: () => import('../views/material/StockData.vue'),
		meta: { title: 'Dispatch | Stock Data' },
	},
	{
		path: '/material/stockmovement',
		name: 'StockMovement',
		component: () => import('../views/material/StockMovement.vue'),
		meta: { title: 'Dispatch | Stock Movement' },
	},
	{
		path: '/material/list',
		name: 'MaterialList',
		component: () => import('../views/material/MaterialList.vue'),
		meta: { title: 'Dispatch | Material List' },
	},
	{
		path: '/audits',
		name: 'ViewAudit',
		component: () => import('../views/audit/ViewAudits.vue'),
		meta: { title: 'Dispatch | System Audit' },
	},
	{
		path: '/schedule',
		name: 'MainSchedule',
		component: () => import('../views/schedule/MainSchedule.vue'),
		meta: { title: 'Dispatch | Schedule' },
	},
	{
		path: '/reports',
		name: 'ProjectReport',
		component: () => import('../views/reports/ProjectReport.vue'),
		meta: { title: 'Dispatch | Project Report' },
	},
	{
		path: '/settings',
		name: 'SettingsPage',
		component: () => import('../views/settings/SettingsPage.vue'),
		meta: { title: 'Dispatch | Settings' },
	},
	{
		path: '/settings/configurations',
		name: 'TicketConfigurations',
		component: () => import('../views/settings/TicketConfigurations.vue'),
		meta: { title: 'Dispatch | Configurations' },
	},
	{
		path: '/settings/roles',
		name: 'ViewRoles',
		component: () => import('../views/settings/ViewRoles.vue'),
		meta: { title: 'Dispatch | Roles' },
	},
	{
		path: '/kpi',
		component: () => import('../views/kpi/BaseKPI.vue'),
		meta: { title: 'Dispatch | KPI ' },
		children: [
			{
				path: 'ticketsReceived',
				component: () => import('../views/kpi/TicketsReceived.vue'),
			},
			{
				path: 'projectType',
				component: () => import('../views/kpi/ProjectType.vue'),
			},
			{
				path: 'teams',
				component: () => import('../views/kpi/Teams.vue'),
			},
			{
				path: 'templates',
				component: () => import('../views/kpi/Problems.vue'),
			},
			{
				path: 'SLA',
				component: () => import('../views/kpi/SLA.vue'),
			},
			{
				path: 'rankings',
				component: () => import('../views/kpi/Rankings.vue'),
			},
		],
	},
	{
        path: "/:pathmatch(.*)",
        name: "NotFound",
        component: () => import('../components/NotFound.vue'),
        meta:{ title: "eFiber | Not Found" }
    },
	{
		path: "/no-permissions",
		name: "NoPermissions",
		component: () => import('../components/NoPermissions.vue'),
		meta:{ title: "eFiber | No Permissions" }
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

// function setupUser(to, from, next) {
// 	const user = isAuthenticated(to);
// 	if (user) {
// 		if (canAccess(user)) {
// 			next();
// 		} else {
// 			next('/no-permissions');
// 		}
// 	} else {
// 		next('/');
// 	}
// }

function canAccess(user, platformId = process.env.VUE_APP_DISPATCH_PLATFORM_ID) {
	// console.log(platformId);
	return user?.platforms?.includes(platformId);
}

async function isAuthenticated(to) {
	if (to.name == '/schedule') {
		if (this.$route.query.changeplatform) {
			localStorage.setItem(
				'access_token',
				JSON.stringify(this.$route.query.changeplatform)
			);
		}
	}
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer ' +
				JSON.parse(localStorage.getItem('access_token')),
		},
	};
	const res = await fetch(
		process.env.VUE_APP_URL + '/users/profile',
		requestOptions
	);
	const data = await res.json();
	// console.log(data);
	if (data.status == true) {
		const user = data.user;
		localStorage.setItem(
			'user_data',
			JSON.stringify(data.user)
		);
		return user;
		// this.user.platformAccess = this.user.platforms.filter(platform => {
		// 	return this.Platforms.find(p => p.id === platform);
		// });
		// // // if user does not have platform access, redirect to no permissions page
		// if (this.user.platformAccess.length === 0 ) {
		// 	this.$router.push('/no-permissions');
		// }
		// this.filterMenuItems();
	} else {
		return null;
	}
}

router.beforeEach(async (to, from, next) => {
    const user = await isAuthenticated(to);
	if (to.name === 'Login') {
		return next(user? '/schedule': undefined);
	} else if (to.name === 'NoPermission') {
		return canAccess(user)? next('/'): next();
	}
	else if (!user) {
		return next('/');
    } else if (!canAccess(user)) {
			if(to.name !== 'NoPermissions') {
				return next('/no-permissions');
			}
			else {
				return next();
			}
	}



	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eslint-disable-next-line no-unused-vars
	to.matched
		.slice()
		.reverse()
		.forEach((match) => {
			if (match.meta && match.meta.title) {
				document.title = match.meta.title;
			}

			// Remove any existing meta tags
			Array.from(
				document.querySelectorAll('meta[name], meta[property]')
			).forEach((el) => el.remove());

			// Add the meta tags defined in the route's meta field
			if (match.meta && match.meta.metaTags) {
				match.meta.metaTags.forEach((tagDef) => {
					const tag = document.createElement('meta');

					Object.keys(tagDef).forEach((key) => {
						tag.setAttribute(key, tagDef[key]);
					});

					document.head.appendChild(tag);
				});
			}
		});

		next();
});

export default router;
