<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
    // all titles will be injected into this template
    titleTemplate: '%s | My Awesome Webapp'
  }
}
</script>

<style>

</style>
