import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ganttastic from '@infectoone/vue-ganttastic'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createI18n } from 'vue-i18n'

import EN from './locales/en.json'
import FR from './locales/fr.json'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss';

const mapsKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
// console.log('maps key', mapsKey)

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: EN,
    fr: FR
  }
})

// console.log(i18n)

// const loadedLocales = {};
// const context = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
// context.keys().forEach((key) => {
//   const locale = key.substring(2, 4)
//   loadedLocales[locale] = context(key)
// });

// Load locale messages, pre-compiled by @intlify/vue-i18n-loader
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// i18n.global.setLocaleMessage('en', loadLocaleMessages()['en'])

// Get locale variable from localStorage
const locale = localStorage.getItem('locale');
i18n.global.locale = locale || 'en';



const switchLanguage = (locale) => {
  i18n.global.locale = locale;
  i18n.global.setLocaleMessage(locale, loadLocaleMessages()[locale])
  // Set locale variable to localStorage
  localStorage.setItem('locale', locale);
}

createApp(App)
.use(router)
.use(i18n)
.use(store)
.use(ganttastic)
.use(VueAxios, axios)
.use(VueGoogleMaps, {
  load: {
    key: mapsKey,
  },
})
.mixin({
    methods: {
      formatDate: function (rawDate, timeOption) {
        if(rawDate){
          const dateNo = new Date(rawDate)
          if(timeOption == "true"){
            var minutes = 0;
            if(dateNo.getMinutes() < 10){
              minutes = "0"+dateNo.getMinutes()
            } else {
              minutes = dateNo.getMinutes()
            }
            var hours = 0;
            if(dateNo.getHours() < 10){
              hours = "0"+dateNo.getHours()
            } else {
              hours = dateNo.getHours()
            }
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
          } else {
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear();
          }
        } else {
          return ""
        }
      },
      formatMoney: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat('en-US', { style: 'currency',currency: 'KES',currencyDisplay: 'narrowSymbol' }).format(value);
        } else {
          return "0.00";
        }
      },
      formatQty: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat().format(value);
        } else {
          return "0";
        }
      },
      switchLanguage: switchLanguage
    },
  })
.mount('#app')